<template>
    <div class="pagination-old clearfix">
        <div class="pagination-old__paginator">
            <ul class="clearfix">
                <li @click="first()" class="mr-2">最初へ</li>
                <li @click="prev()" class="mr-2">前へ</li>
                <li v-for="(page, index) in pageList" :key="index" :class="page.class" @click="goPage(page)">
                    {{ page.value }}
                </li>
                <li @click="next()" class="ml-2">次へ</li>
                <li @click="last()" class="ml-2">最後へ</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'pagination',
    props: {
        total: {
            type: Number,
            require: true
        },
        page: {
            type: Number,
            require: true
        },
        perPage: {
            type: Number,
            require: true
        }
    },

    data: function () {
        return {
            limit: this.perPage,
            perPageList: [10, 15, 20, 25]
        };
    },
    methods: {
        goPage(page) {
            if (page.class !== 'dot' && page.class !== 'active') {
                this.$emit('update:page', page.value);
            }
        },
        prev() {
            if (this.page > 1) {
                this.$emit('update:page', this.page - 1);
            }
        },
        next() {
            if (this.page < this.totalPage) {
                this.$emit('update:page', this.page + 1);
            }
        },
        first() {
            this.$emit('update:page', 1);
        },
        last() {
            this.$emit('update:page', this.totalPage);
        },
        onChange() {
            this.$emit('updatePerPage', this.limit);
        }
    },
    computed: {
        totalPage: function () {
            return Math.ceil(this.total / this.perPage);
        },
        from: function () {
            return (this.page - 1) * this.perPage + 1;
        },
        to: function () {
            let to = this.page * this.perPage;
            return to > this.total ? this.total : to;
        },
        pageList: function () {
            let pageList = [];

            for (let i = 1; i <= this.totalPage; i++) {
                let page = null;
                if (i === this.page) {
                    page = {
                        value: i,
                        class: 'active'
                    };
                } else if (i === 1 || i === this.totalPage || (i === 2 && this.page - i === 1) || (i - this.page === 1 && this.totalPage - i === 1)) {
                    page = {
                        value: i,
                        class: ''
                    };
                } else if ((i < this.page && i - 1 > 1) || (i > this.page && this.totalPage - i > 1)) {
                    if (pageList[pageList.length - 1].class !== 'dot') {
                        page = {
                            value: '...',
                            class: 'dot'
                        };
                    }
                }

                if (page) {
                    pageList.push(page);
                }
            }

            return pageList;
        }
    }
};
</script>

<style lang="scss">
.pagination-old {
    padding: 10px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .pagination__items__label {
        font-size: 13px;
        margin-right: 5px;
    }
    .pagination__items__result {
        margin-left: 6px;
        margin-right: 8px;

        font-size: 13px;
    }
    .el-input__inner {
        line-height: 20px;
        height: 20px;
        width: 50px;
        border-radius: 4px;
        padding-right: 15px !important;
        padding-left: 7px;
        font-size: 12px;
    }
    .el-input__icon {
        width: 15px;
        line-height: 20px;
    }
    &__items {
        .el-select {
            width: 30px !important;
        }
        float: left;

        > * {
            display: block;
            float: left;
        }

        &__label {
            margin-right: 10px;
            line-height: 20px;
        }

        &__select {
            width: 30px !important;
            position: relative;
            margin-right: 15px;
            .el-input {
                input {
                    border: none !important;
                    padding: 0;
                    line-height: 20px;
                    height: 20px;
                }

                .el-select__caret {
                    width: 10px;
                    height: 5px;

                    &.is-reverse {
                    }

                    &:before {
                        content: '';
                    }
                }

                &__suffix {
                    right: 0;
                    top: 7px;
                    width: 10px;
                    height: 5px;
                }
            }
        }

        &__result {
            line-height: 20px;
            margin-left: 6px;
            display: inline-block;
        }
    }

    &__paginator {
        float: right;

        &__result {
            float: left;
            margin-right: 25px;
            line-height: 20px;
        }

        ul {
            float: left;
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                float: left;
                line-height: 20px;
                margin-right: 8px;
                cursor: pointer;
                font-size: 16px;

                &:last-child {
                    margin: 0;
                }

                &.active {
                    cursor: unset;
                }

                &.dot {
                    cursor: unset;
                }
            }
        }
    }
}
</style>
